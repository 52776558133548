<template>
  <router-link to="/room-creation" class="btn btn-factory fs-30" v-tooltip.bottom="'Create Room'"><i class="zmdi zmdi-plus-square"></i></router-link>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
</style>
