function start(el, clsList) {
  el.classList.add('animated')
  el.classList.add('infinite')
  clsList.forEach((cls) => {
    el.classList.add(cls)
  })
}

function stop(el, clsList) {
  el.classList.remove('animated')
  el.classList.remove('infinite')
  clsList.forEach((cls) => {
    el.classList.remove(cls)
  })
}

export default {
  inserted(el, binding) {
    let clsList = [binding.arg]
    if (binding.value && binding.value.clsList) {
      clsList = clsList.concat(binding.value.clsList)
    }
    let timeoutId = null
    el.animate = function () {
      if (timeoutId) {
        clearTimeout(timeoutId)
        timeoutId = null
        stop(el, clsList)
      }
      start(el, clsList)
      timeoutId = setTimeout(() => stop(el, clsList), 500)
    }
  }
}
