<template>
  <div class="d-flex align-items-center">
    <span class="profile-link"
          @click="showProfile"
    >
      <Clamp class="font-weight-bold"
             :maxLines="1"
             :tooltip="tooltip"
      >{{username}}</Clamp>
    </span>
    <slot></slot>
  </div>
</template>

<script>
import Clamp from '@/components/common/Clamp'

export default {
  components: {
    Clamp,
  },
  props: {
    userId: {
      type: String,
      default: null
    },
    username: {
      type: String,
      default: null
    },
    tooltip: {
      type: String,
      default: null
    },
  },
  methods: {
    showProfile(e) {
      e.preventDefault()
      this.$store.commit('sidebar/toggle', { id: 'profile', title: 'Profile', userId: this.userId })
    }
  }
}
</script>

<style scoped lang="scss">
.profile-link {
  outline: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
