<template>
  <button class="btn btn-notifications fs-30" v-tooltip.bottom="'Notifications'" @click="onClick">
    <i class="zmdi zmdi-notifications"></i>
    <span ref="badge" class="badge"
          v-if="notificationBubble.count"
          v-animate:heartBeat
    >{{notificationBubble.count}}</span>
  </button>
</template>

<script>
import { mapState } from 'vuex'
import config from '@/config'
import animate from '@/directives/animate'
import usersClient from '@/clients/users.client'
import { getApiAuth } from '@/utils/credentials.utils'
import { playNotificationSoft } from '../../utils/sound.utils'

const { apiUrl } = config

export default {
  directives: {
    animate
  },
  computed: {
    ...mapState('bubble', {
      notificationBubble: state => state.notificationBubble
    })
  },
  watch: {
    notificationBubble(data) {
      if (data.count) {
        this.$nextTick(async () => {
          if (!data.silent) {
            await playNotificationSoft()
          }
          this.$refs.badge.animate()
        })
      }
    }
  },
  async mounted() {
    usersClient.on('notification', this.onNotification)
    await this.syncNotificationCount()
  },
  beforeDestroy() {
    usersClient.off('notification', this.onNotification)
  },
  methods: {
    onClick() {
      this.$store.commit('sidebar/toggle', { id: 'notifications', title: 'Notifications' })
      this.$parent.$emit('collapse')
    },
    onNotification(notification) {
      this.$store.commit('bubble/incNotificationBubble', { silent: notification.silent })
    },
    async syncNotificationCount() {
      const bubbleResponse = await this.$http.get(apiUrl + '/bubbles/notifications', {
        auth: getApiAuth()
      })
      const { viewedAt: createdAtGte } = bubbleResponse.data.result || {}
      const notificationCountResponse = await this.$http.get(apiUrl + '/notifications/count', {
        auth: getApiAuth(),
        params: {
          createdAtGte
        }
      })
      this.$store.commit('bubble/setNotificationBubble', {
        count: notificationCountResponse.data.result,
        silent: true
      })
    }
  }
}
</script>

<style scoped lang="scss">
.btn-notifications {
  position: relative;
}
.badge {
  color: white !important;
  background-color: $bc-red-100;
  font-size: 12px;
  @include media-breakpoint-up(sm) {
    position: absolute;
    right: 0;
    top: 5px;
  }
}
</style>
