<template>
  <v-clamp autoresize :max-lines="maxLines" v-tooltip.right="clampState ? tooltip : ''" @clampchange="onClampChange">
    <slot></slot>
  </v-clamp>
</template>

<script>
import VClamp from 'vue-clamp'
export default {
  components: {
    VClamp
  },
  props: {
    tooltip: {
      type: String,
      default: ''
    },
    maxLines: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      clampState: false
    }
  },
  methods: {
    onClampChange(state) {
      this.clampState = state
    }
  }
}
</script>

<style scoped lang="scss">
</style>
