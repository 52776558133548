<template>
  <div class="dropdown" ref="dropdown">
    <button class="btn hp-57" @click="toggle = !toggle">
      <div class="d-flex align-items-center">
        <Avatar class="avatar-sm mr-2"
            :userId="userId"
            :avatarVersion="avatarVersion"
            :alt="username"
        ></Avatar>
        <Username class="dropdown-toggle"
            :userId="userId"
            :username="username"
            :tooltip="username"
        ></Username>
      </div>
    </button>
    <div class="dropdown-menu dropdown-menu-right show" v-if="toggle" v-click-outside="onClickOutside">
      <router-link class="dropdown-item"
                   :to="`/rooms/${room.id}`"
                   v-if="room"
      >
        <i class="zmdi zmdi-tv-play mr-2"></i>My Room
      </router-link>
      <button class="dropdown-item" @click="onClickSettings">
        <i class="zmdi zmdi-settings mr-2"></i>Settings
      </button>
      <div class="dropdown-divider"></div>
      <button class="dropdown-item" @click="onClickSignout"><i class="zmdi zmdi-power mr-2"></i>Sign Out</button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import config from '@/config'
import Avatar from '@/components/common/Avatar'
import Username from '@/components/common/Username'
import { removeCredentials, getApiAuth } from '@/utils/credentials.utils'

const { apiUrl } = config

export default {
  components: {
    Avatar,
    Username,
  },
  data() {
    return {
      toggle: false,
    }
  },
  computed: {
    ...mapState('account', {
      userId: state => state.id,
      username: state => state.username,
      avatarVersion: state => state.avatarVersion,
      room: state => state.room,
    })
  },
  watch: {
    '$route'() {
      this.toggle = false
    }
  },
  async mounted() {
    await this.fetchAccount()
  },
  methods: {
    async fetchAccount() {
      const response = await this.$http.get(apiUrl + '/account', {
        auth: getApiAuth()
      })
      this.$store.commit('account/setAccount', response.data.result)
    },
    onClickSettings() {
      this.toggle = false
      this.$parent.$emit('collapse')
      this.$store.commit('sidebar/toggle', { id: 'settings', title: 'Settings' })
    },
    onClickOutside(e) {
      if (!this.$refs.dropdown.contains(e.target)) {
        this.toggle = false
      }
    },
    onClickSignout() {
      this.toggle = false
      this.$confirm({
        inputs: [
          {
            label: 'Sign out of all my sessions',
            value: false,
            type: 'toggle-button',
            name: 'closeAll',
            id: 'closeAll',
          }
        ],
        callback: (confirm, { inputValues }) => {
          if (confirm) {
            this.signout(inputValues.closeAll)
          }
        }
      })
    },
    async signout(closeAll) {
      try {
        await this.$http.post(apiUrl + '/account/signout', {
          closeAll
        }, {
          auth: getApiAuth()
        })
      } catch (e) {
        console.error(e)
      }
      removeCredentials()
      this.$store.commit('account/resetState')
      this.$store.commit('bubble/resetState')
      this.$store.commit('common/resetState')
      this.$store.commit('room/resetState')
      this.$store.commit('sidebar/resetState')
      await this.$router.push({ name: 'SigninPage' })
    },
  }
}
</script>

<style scoped lang="scss">
.dropdown-divider {
  border-top: 1px solid $bc-gray-600;
}
.dropdown-menu {
  width: 100%;
  background-color: $bc-gray-800;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
  margin: 0;
  border: 0;
  @include media-breakpoint-up(md) {
    min-width: 180px;
  }
}
.dropdown-item {
  color: $bc-gray-100;
  &:focus {
    outline: none;
    background: transparent;
  }
  &:hover {
    color: $bc-gray-100;
    background: rgba(0, 0, 0, 0.2);
  }
  &:active {
    color: $bc-gray-100;
    background: rgba(0, 0, 0, 0.3);
  }
}
</style>
