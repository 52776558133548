<template>
  <Header>
    <template slot="content">
      <MenuBtn></MenuBtn>
      <TrendingLink></TrendingLink>
<!--      <InvitationsLink></InvitationsLink>-->
      <div class="d-flex ml-auto">
        <RoomCreationBtn></RoomCreationBtn>
<!--        <FriendsBtn></FriendsBtn>-->
<!--        <MessagesBtn></MessagesBtn>-->
        <NotificationsBtn></NotificationsBtn>
      </div>
      <AccountBtn></AccountBtn>
    </template>
  </Header>
</template>

<script>
import Header from '@/components/header/Header'
import MenuBtn from '@/components/header/MenuBtn'
import TrendingLink from '@/components/header/TrendingLink'
// import InvitationsLink from '@/components/header/InvitationsLink'
// import FriendsBtn from '@/components/header/FriendsBtn'
import RoomCreationBtn from '@/components/header/RoomCreationBtn'
import NotificationsBtn from '@/components/header/NotificationsBtn'
// import MessagesBtn from '@/components/header/MessagesBtn'
import AccountBtn from '@/components/header/AccountBtn'
export default {
  components: {
    Header,
    MenuBtn,
    TrendingLink,
    // InvitationsLink,
    // FriendsBtn,
    RoomCreationBtn,
    NotificationsBtn,
    // MessagesBtn,
    AccountBtn,
  }
}
</script>

<style scoped lang="scss">
</style>
