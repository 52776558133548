<template>
  <div class="dropdown" ref="dropdown">
    <button class="btn btn-menu fs-30" v-tooltip.bottom="'Menu'" @click="toggle = !toggle">
      <i class="zmdi zmdi-widgets"></i>
    </button>
    <transition
        name="menu-transition"
        enter-active-class="animated faster zoomIn"
        leave-active-class="animated faster bounceOutLeft"
    >
      <div class="dropdown-menu dropdown-menu-left show" v-if="toggle" v-click-outside="onClickOutside">
        <vue-scroll>
          <div class="menu">
            <div class="container">
              <div class="row">
                <div class="col-24 pt-2 category-block">
                  <div
                      @click="toggle = false"
                  >
                    <router-link class="btn btn-menu"
                                 :to="'/categories/' + category.value"
                                 v-for="category in categories"
                                 :key="category.name"
                    >
                      {{category.name}}
                      <span class="badge">{{getRoomCount(category.value)}}</span>
                    </router-link>
                  </div>
                </div>
                <div class="col-24 other-block">
                  <div class="dropdown-divider"></div>
                  <a class="btn btn-menu" href="https://discordapp.com/invite/JC3nqRj"
                     v-tooltip.top="'Contact Us On Discord'"
                  >Discord</a>
                  <a class="btn btn-menu" href="https://www.patreon.com/bearcat_team"
                     v-tooltip.top="'Support Us On Patreon'"
                  >Patreon</a>
                  <router-link class="btn btn-menu"
                               :to="'/terms'"
                               v-tooltip.top="'Terms of Service'"
                  >
                    Terms of Service
                  </router-link>
                  <router-link class="btn btn-menu"
                               :to="'/privacy'"
                               v-tooltip.top="'Privacy Policy'"
                  >
                    Privacy Policy
                  </router-link>
                  <router-link class="btn btn-menu"
                               :to="'/premium'"
                               v-tooltip.top="'Premium'"
                  >
                    Premium
                  </router-link>
<!--                  <router-link class="btn btn-menu"-->
<!--                               :to="'/support'"-->
<!--                               v-tooltip.top="'Support'"-->
<!--                  >-->
<!--                    Support-->
<!--                  </router-link>-->
<!--                  <router-link class="btn btn-menu"-->
<!--                               :to="'/contact'"-->
<!--                               v-tooltip.top="'Contact Us'"-->
<!--                  >-->
<!--                    Contact Us-->
<!--                  </router-link>-->
<!--                  <router-link class="btn btn-menu"-->
<!--                               :to="'/hosting'"-->
<!--                  >-->
<!--                    Self Hosting-->
<!--                  </router-link>-->
                </div>
                <div class="col-24 footer-block text-center pt-2">
                  <span class="text-muted small">Made with 💜 in Russia</span>
                </div>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import config from '@/config'
import { getApiAuth } from '@/utils/credentials.utils'

const { apiUrl } = config

export default {
  computed: {
    ...mapState('common', {
      categories: state => state.roomCategories,
      roomCount: state => state.roomCount,
    })
  },
  data() {
    return {
      toggle: false,
    }
  },
  async mounted() {
    await this.render()
  },
  methods: {
    async fetchCategories() {
      const response = await this.$http.get(apiUrl + '/rooms/categories', {
        auth: getApiAuth()
      })
      const categories = response.data.result
      this.$store.commit('common/setRoomCategories', categories)
    },
    async fetchRoomCount() {
      const response = await this.$http.get(apiUrl + '/rooms/count', {
        auth: getApiAuth()
      })
      const roomCount = response.data.result
      this.$store.commit('common/setRoomCount', roomCount)
    },
    async render() {
      await this.fetchCategories()
      await this.fetchRoomCount()
    },
    onClickOutside(e) {
      if (!this.$refs.dropdown.contains(e.target)) {
        this.toggle = false
      }
    },
    getRoomCount(categoryId) {
      const category = _.find(this.roomCount, { categoryId }) || {}
      return category.count
    }
  }
}
</script>

<style scoped lang="scss">
.dropdown-divider {
  border-top: 1px solid $bc-gray-600;
}
.dropdown-menu {
  width: 100%;
  background-color: $bc-gray-800;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
  margin: 0;
  border: 0;
  @include media-breakpoint-up(md) {
    min-width: 570px;
  }
  @include media-breakpoint-up(lg) {
    min-width: 760px;
  }
}
.menu {
  max-height: calc(100vh - 140px);
}
.btn-menu {
  color: $bc-gray-100;
}
.badge {
  color: $bc-gray-000;
  background-color: $bc-purple-300;
}
</style>
