<template>
  <router-link class="btn lh-45" to="/trending" v-tooltip.bottom="'Trending Rooms'">
    Trending
    <span class="badge">{{getTrendingRoomCount()}}</span>
  </router-link>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('common', {
      roomCount: state => state.roomCount,
    })
  },
  methods: {
    getTrendingRoomCount() {
      return _.sumBy(this.roomCount, 'count') || null
    }
  }
}
</script>

<style scoped lang="scss">
.badge {
  color: $bc-gray-000;
  background-color: $bc-purple-300;
}
</style>
